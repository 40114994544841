import { createGlobalStyle } from "@xstyled/styled-components";

import {
    healine1Style,
    healine2Style,
    healine3Style,
    healine4Style,
    healine5Style,
    healine6Style,
    inlineStyle,
    linkStyle,
    tableStyle,
} from "./typography";

const GlobalStyle = createGlobalStyle`
    html {
        font-family: "Nunito", sans-serif;
        color: dark;
    }

    /* FIXME: provide a better selector to target tour pages and blog posts */
    
    .template-tour,
    .template-post {
        h1, h2, h3, h4, h5, h6{
            font-family: "Josefin Sans", sans-serif;
        }

        h1 {
            ${healine1Style}
            padding-bottom: 3;
        }

        h2 {
            ${healine2Style}
            padding-top: 3;
            padding-bottom: 3;
        }
        
        h3 {
            ${healine3Style}
            padding-bottom: 3;
        }
        
        h4 {
            ${healine4Style}
            padding-bottom: 3;
        }
        
        h5 {
            ${healine5Style}
            padding-bottom: 3;
        }
        
        h6 {
            ${healine6Style}
            padding-bottom: 3;
        }

        ul, ol, p, blockquote, hr, table {
            ${inlineStyle}
        }

        li > p { margin: 0; }

        li > p + p { margin-top: 3; }

        ul, ol {
            ul, ol {
                margin-bottom: 0;
            }
            li {
                margin-left: 3;
                padding-left: 2;
            }

        }

        ol > li { list-style-type: decimal; }

        ul > li { list-style-type: "-"; }

        blockquote {
            padding-left: 3;
            margin-left: 5;
            position: relative;
            border-left: 1px solid;
            border-left-color: primary;

            p::before {
                content: "”";
                display: block;
                pointer-events: none;
                font-size: 9;
                width: 27px;
                height: 27px;
                line-height: 66px;
                margin-right: 3;
                color: primary;
                position: absolute;
                top: calc(50% - 13px);
                left: -43px;
            }
        }

        figure figcaption {
            padding: 1 3;
            font-size: 3;
            font-weight: bold;
        }

        a {
            ${linkStyle};
        }

        table {
            ${tableStyle};
        }
    }
`;

export default GlobalStyle;
