export default {
    instagram: {
        label: "Instagram",
        accountUrl: "https://www.instagram.com/parisinsight_tours/",
        getAccountData: "https://www.instagram.com/parisinsight_tours/?__a=1",
        getPostData:
            "https://graph.facebook.com/v8.0/instagram_oembed?url=https://www.instagram.com/p/",
        appId: "1085262531933165",
        clientAccessToken: "266d53bc584d148e0d0f267bd665049c",
    },
    facebook: {
        label: "Facebook",
        accountUrl: "https://www.facebook.com/Paris-insight-111831347290893/",
    },
    twitter: {
        label: "Twitter",
        accountUrl: "https://twitter.com/paris_insight",
    },
    tripadvisor: {
        label: "Tripadvisor",
        accountUrl:
            "https://www.tripadvisor.com/Attraction_Review-g187147-d21182183-Reviews-Paris_Insight-Paris_Ile_de_France.html",
    },
};
