import styled from "@xstyled/styled-components";
import { space, textAlign } from "@xstyled/system";

import {
    healine1Style,
    healine2Style,
    healine3Style,
    healine4Style,
    healine5Style,
    healine6Style,
} from "../../../style/typography";

const getStyle = (variant) => {
    switch (variant) {
        case "h1":
            return healine1Style;
        case "h2":
            return healine2Style;
        case "h3":
            return healine3Style;
        case "h4":
            return healine4Style;
        case "h5":
            return healine5Style;
        case "h6":
            return healine6Style;
        default:
            return healine1Style;
    }
};

const Headline = styled.box`
    ${({ variant }) => getStyle(variant)}
    ${space};
    ${textAlign};
`;

Headline.defaultProps = {
    as: "h1",
};

export default Headline;
