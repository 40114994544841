import {
    faFacebook,
    faInstagram,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

import social from "../../../constants/social";
import S from "./social-link.style";

const SocialLink = ({ type }) => (
    <S.container
        href={social[type].accountUrl}
        type={type}
        rel="noopener noreferrer"
        target="_blank"
    >
        <div>
            <S.icon>
                {type === "facebook" && <FontAwesomeIcon icon={faFacebook} />}
                {type === "instagram" && <FontAwesomeIcon icon={faInstagram} />}
                {type === "twitter" && <FontAwesomeIcon icon={faTwitter} />}
            </S.icon>
            <S.text>{social[type].label}</S.text>
        </div>
        <S.arrow icon={faArrowRight} />
    </S.container>
);

SocialLink.propTypes = {
    type: PropTypes.oneOf(["facebook", "instagram", "twitter"]),
};

export default SocialLink;
