import { css } from "@xstyled/styled-components";

const fontFamily = '"Josefin Sans", sans-serif;';

export const healine1Style = css`
    font-family: ${fontFamily};
    font-size: 7;
    font-weight: 600;
    color: primary;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
`;

export const healine2Style = css`
    font-family: ${fontFamily};
    font-size: 6;
    font-weight: 600;
    color: dark;
    text-transform: none;
    margin: 0;
    padding: 0;
`;

export const healine3Style = css`
    font-family: ${fontFamily};
    font-size: 5;
    font-weight: 600;
    color: primary;
    text-transform: none;
    margin: 0;
    padding: 0;
`;

export const healine4Style = css`
    font-family: ${fontFamily};
    font-size: 4;
    font-weight: normal;
    color: dark;
    text-transform: none;
    margin: 0;
    padding: 0;
`;

export const healine5Style = css`
    font-family: ${fontFamily};
    font-size: 3;
    font-weight: 600;
    color: primary;
    text-transform: none;
    margin: 0;
    padding: 0;
`;

export const healine6Style = css`
    font-family: ${fontFamily};
    font-size: 3;
    font-weight: normal;
    color: dark;
    text-transform: none;
    margin: 0;
    padding: 0;
`;

export const inlineStyle = css`
    font-family: "Nunito", sans-serif;
    margin-bottom: 3;
    font-size: 3;
`;

export const tableStyle = css`
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        padding: 1 3;
    }

    thead {
        background-color: primaryLight;
    }

    tbody {
        border-top: 1px solid;
        border-top-color: dark;
        tr {
            border-bottom: 1px solid;
            border-bottom-color: primary;
        }
    }
`;

export const linkStyle = css`
    color: primary;
    text-decoration: underline;
    font-weight: 600;
    transition: color 0.25s ease-in-out;

    &:hover {
        color: dark;
    }
`;
