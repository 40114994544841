import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import styled, { breakpoints, css } from "@xstyled/styled-components";
import { Link } from "gatsby";

const S = styled.nav`
    height: 100%;
`;

S.burgerButton = styled.div(
    breakpoints({
        xs: css`
            position: absolute;
            width: 52px;
            height: 52px;
            display: flex;
            top: 0;
            right: 0;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.25s ease-in-out;

            &:hover {
                background-color: primaryLight;
            }
        `,
        md: css`
            display: none;
        `,
    })
);

S.burgerButtonIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
`;

S.list = styled.ul(
    breakpoints({
        xs: css`
            align-items: center;
            background-color: light;
            bottom: 0;
            display: ${({ open }) => (open ? "flex" : "none")};
            flex-direction: column;
            justify-content: center;
            left: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            position: fixed;
            right: 0;
            top: 52px;
        `,
        md: css`
            bottom: auto;
            display: flex;
            flex-direction: row;
            height: 100%;
            left: auto;
            position: relative;
            right: auto;
            top: auto;
        `,
    })
);

S.item = styled.li(
    breakpoints({
        xs: css`
            margin: 0 0 8px;
        `,
        md: css`
            margin: 0 0 0 18px;

            &:first-child {
                margin: 0;
            }
        `,
    })
);

S.link = styled(Link)(
    breakpoints({
        xs: css`
            color: dark;
            font-family: "Josefin Sans", sans-serif;
            font-size: 4;
            font-weight: 500;
            text-decoration: none;
            text-transform: capitalize;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: primary;
            }

            &.active {
                color: primary;
                font-weight: 700;
                text-decoration: none;
            }
        `,
        md: css`
            font-size: 3;
        `,
    })
);

S.button = styled(Button)`
    font-size: 3;
    line-height: 1;
    padding: 2 12px;
    text-transform: none;
`;

export default S;
