import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@xstyled/styled-components";
import { th } from "@xstyled/system";

const S = {};

S.arrow = styled(FontAwesomeIcon)`
    transition: 0.35s transform;
    transform: rotate(-45deg);
    justify-self: flex-end;
`;

S.container = styled.a`
    border-radius: 6;
    box-shadow: regular;
    padding: 4;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ type }) => th(`colors.${type}`)};
    color: #fff;

    &:hover {
        ${S.arrow} {
            transform: rotate(-45deg) translateX(5px);
        }
    }
`;

S.icon = styled.span`
    font-size: 4;
    margin-right: 2;
`;

S.text = styled.span`
    font-size: 3;
    font-weight: bold;
`;

export default S;
