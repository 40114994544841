import { Link } from "gatsby";
import React from "react";

import logo from "../../../images/logo.svg";
import Navigation from "../Navigation/navigation";
import S from "./header.style";

const Header = () => (
    <S>
        <S.container>
            <Link to="/">
                <S.logo src={logo} width={220} alt="Parisinsight" />
            </Link>
            <Navigation />
        </S.container>
    </S>
);

export default Header;
