import styled from "@xstyled/styled-components";
import { space, zIndex } from "@xstyled/system";

import { CONTAINER } from "../../../style/layout";

const Container = styled.div`
    ${CONTAINER};
    ${space};
    ${zIndex};
`;

export default Container;
