import { Link } from "gatsby";
import React from "react";

import logo from "../../../images/logo.svg";
import S from "./footer.style";

const Footer = () => (
    <S>
        <S.container>
            <Link to="/">
                <S.logo src={logo} width={150} alt="Parisinsight" /> ©{" "}
                {new Date().getFullYear()}
            </Link>
        </S.container>
    </S>
);

export default Footer;
