import { createMuiTheme } from "@material-ui/core/styles";
import { rpxTransformers } from "@xstyled/system";

export const SCTheme = {
    colors: {
        primary: "#6a7f8c",
        primaryLight: "#bfcfd8",
        primaryExtraLight: "#e2eef5",
        dark: "#1f1f1f",
        light: "white",
        instagram: "#0095f6",
        facebook: "#4267b2",
        twitter: "#1da1f2",
        tripadvisor: "#00aa6c",
    },
    shadows: {
        regular: "0 6px 18px #ebebeb, 0 -6px 18px #ffffff",
        regularHover: "0 6px 18px #dcdcdc, 0 -6px 18px #ffffff",
        header: "0px 20px 40px rgba(0, 0, 0, 0.05)",
        footer: "0 50vh 0 50vh #6a7f8c",
        dark: "0 6px 18px #7b7b7b, 0 -6px 18px #5d555557",
    },
    transformers: {
        ...rpxTransformers,
    },
};

export const MUITheme = createMuiTheme({
    typography: {
        fontFamily: '"Josefin Sans", sans-serif',
    },
    palette: {
        primary: {
            main: SCTheme.colors.primary,
        },
    },
});
