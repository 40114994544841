import styled from "@xstyled/styled-components";

const SectionSeparator = styled.hr`
    border-top: 1px solid;
    border-top-color: primaryExtraLight;
    width: 33%;
    margin: 0 auto;
    padding: 0;
`;

export default SectionSeparator;
