import { Box } from "@xstyled/styled-components";
import React from "react";

import Headline from "../../typography/Headline/headline";
import SocialLink from "../SocialLink/social-link";

const SocialPush = () => (
    <Box mb={5}>
        <Headline textAlign="center" variant="h2" as="h2" my={5}>
            Join me on social media
        </Headline>
        <Box px={0}>
            <Box row mx={{ xs: 0, md: -3 }}>
                {["facebook", "instagram" /*, "twitter"*/].map((type) => (
                    <Box
                        key={type}
                        px={{ xs: 0, md: 3 }}
                        mb={3}
                        col={{ xs: 1, md: 1 / 2 }}
                    >
                        <SocialLink type={type} />
                    </Box>
                ))}
            </Box>
        </Box>
    </Box>
);
export default SocialPush;
