import "./normalize.css";
import "./reset.css";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Box, ThemeProvider } from "@xstyled/styled-components";
import PropTypes from "prop-types";
import React from "react";

import GlobalStyle from "../../../style/global";
import { MUITheme, SCTheme } from "../../../style/theme";
import SectionSeparator from "../../common/SectionSeparator/section-separator";
import Container from "../Container/container";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import SocialPush from "../SocialPush/social-push";

const Main = ({ children }) => (
    <ThemeProvider theme={SCTheme}>
        <GlobalStyle />
        <MuiThemeProvider theme={MUITheme}>
            <Header />
            <Box as="main" pt={52} zIndex={1} position="relative">
                {children}
                <Container>
                    <SectionSeparator />
                    <SocialPush />
                </Container>
            </Box>
            <Footer />
        </MuiThemeProvider>
    </ThemeProvider>
);

Main.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default Main;
