import { css } from "@xstyled/styled-components";
import { breakpoints } from "@xstyled/system";

export const DEFAULT_BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};

export const CONTAINER = css(
    breakpoints({
        xs: css`
            margin: 0 auto;
            padding: 0 3;
            max-width: 100%;
        `,
        sm: css`
            max-width: ${DEFAULT_BREAKPOINTS.sm}px;
        `,
        md: css`
            max-width: ${DEFAULT_BREAKPOINTS.md}px;
        `,
        lg: css`
            max-width: ${DEFAULT_BREAKPOINTS.lg}px;
        `,
        xl: css`
            max-width: ${DEFAULT_BREAKPOINTS.xl}px;
        `,
    })
);

export const CONTAINER_NARROW = css`
    margin: 0 auto;
    padding: 0 3;

    ${breakpoints({
        xs: css`
            margin: 0 auto;
            padding: 0 3;
            max-width: 100%;
        `,
        sm: css`
            max-width: ${DEFAULT_BREAKPOINTS.sm}px;
        `,
        md: css`
            max-width: ${DEFAULT_BREAKPOINTS.md}px;
        `,
    })}
`;
