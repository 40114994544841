import styled, { css } from "@xstyled/styled-components";
import { breakpoints } from "@xstyled/system";

import { CONTAINER } from "../../../style/layout";

const S = styled.header`
    background-color: light;
    box-shadow: header;
    height: 52px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
`;

S.container = styled.div(
    breakpoints({
        xs: css`
            ${CONTAINER};
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: flex;
            padding-bottom: 2;
            padding-top: 2;
            position: relative;
        `,
        md: css`
            justify-content: space-between;
        `,
    })
);

S.logo = styled.img`
    display: block;
`;

export default S;
