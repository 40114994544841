import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import React, { useState } from "react";

import S from "./navigation.style";

const Navigation = () => {
    const [open, setOpen] = useState(false);

    const handleBurgerClick = () => {
        setOpen(!open);
    };

    return (
        <S>
            <S.burgerButton onClick={handleBurgerClick}>
                <S.burgerButtonIcon icon={open ? faTimes : faBars} />
            </S.burgerButton>
            <S.list open={open} onClick={handleBurgerClick}>
                <S.item>
                    <S.link activeClassName="active" to="/">
                        Home
                    </S.link>
                </S.item>
                <S.item>
                    <S.link
                        activeClassName="active"
                        to="/tours"
                        partiallyActive={true}
                    >
                        Tours
                    </S.link>
                </S.item>
                <S.item>
                    <S.link activeClassName="active" to="/reviews">
                        Reviews
                    </S.link>
                </S.item>
                <S.item>
                    <S.link activeClassName="active" to="/faq">
                        FAQ
                    </S.link>
                </S.item>
                <S.item>
                    <S.link activeClassName="active" to="/about">
                        About
                    </S.link>
                </S.item>
                <S.item>
                    <S.link
                        activeClassName="active"
                        to="/news"
                        partiallyActive={true}
                    >
                        News
                    </S.link>
                </S.item>
                <S.item>
                    <Link to="/contact">
                        <S.button variant="contained" color="primary">
                            Book a tour
                        </S.button>
                    </Link>
                </S.item>
            </S.list>
        </S>
    );
};

export default Navigation;
