import styled from "@xstyled/styled-components";

import { CONTAINER } from "../../../style/layout";

const S = styled.footer`
    background-color: primaryLight;
    box-shadow: header;
    z-index: 2;
`;

S.container = styled.div`
    ${CONTAINER};
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2;
    padding-top: 2;
    position: relative;
`;

S.logo = styled.img`
    margin-top: 4px;
`;

export default S;
